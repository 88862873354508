
<script>
import { offset } from '@/assets/js/utils/Utils.js'
export default {
  data() {
    return {
      offset: 0,
      shown: false
    }
  },
  methods:{
    setOffset() {
      this.offset = offset(this.$el).top
    },
    tick(scrollTop, screenH) {
      if(this.offset === 0) return
      if(this.shown) return
      if(scrollTop - screenH * .2> this.offset - screenH ) this.shown = true
    }
  }
} 
</script>

