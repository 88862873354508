<template>
  <strong class="title mbSml">
    <span class="bar" :style="{ backgroundColor: fontColor} "></span>
    <span v-text="title"></span>
  </strong>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["title", "color"],
  computed: {
    ...mapState(["fontColor"])
  },
  mounted() {}
};
</script>

<style lang="stylus">
@import './../styles/variables.styl';

.title {
  line-height: 1;
  position: relative;
  font-size: 18px;
  font-weight: 500;
}
</style>
