
<template>
  <div class="projectLink js-opacity" :class="{'no-opacity': projectImg === project.slug}">
    <strong v-if="isNext" class="mbSml">Next</strong>
    <router-link
      :to="{ name: 'project', params: { slug: project.slug }}"
      @mouseenter.native="onEnter($event)"
      @mouseleave.native="onLeave($event)"
      class="project"
    >
      <span v-text="project.client_name" class="client"></span>
      <br />
      <span>
        <span class="bar" :style="{'background': fontColor}"></span>
        <span v-text="project.project_name" class="project"></span>
      </span>
      <span class="media" ref="media" :class="{isHover}" v-if="!isDevice">
        <img :src="url" :alt="project.project_name" v-if="project.cover_type === 'image'" />
        <video :src="project.cover.url" autoplay playsinline muted loop preload="auto" v-else></video>
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import transform from "dom-transform";
import MouseHelper from "@/assets/js/utils/MouseHelper";
import ResizeHelper from "@/assets/js/utils/ResizeHelper";
export default {
  props: ["project", "color", "isNext"],
  data() {
    return {
      isHover: false,
      isMoving: false
    };
  },
  computed: {
    ...mapState(["currentProjectImg", "home", "fontColor", "cloudinary"]),
    ...mapGetters(["projectImg", "isDevice"]),
    url() {
      //return this.src
      let w =
        "f_auto,w_" +
        Math.max(Math.ceil((ResizeHelper.width() * 0.45) / 500) * 500, 780) +
        "/";
      let url = this.project.cover.url.replace(
        this.cloudinary,
        this.cloudinary + w
      );
      //url = this.webp ? url.replace('.jpeg', '.webp').replace('.jpg', '.webp') : url
      return url;
    }
  },
  methods: {
    ...mapActions([
      "setProjectImg",
      "resetProjectImg",
      "setColors",
      "resetColors"
    ]),
    resize(w, h) {
      if (w && h) {
        this.w = w;
        this.h = h;
      }
      if (this.isDevice) return;
      this.left = this.$el.getBoundingClientRect().left;
      this.mediaW = this.$refs.media.getBoundingClientRect().width;
      this.mediaH = this.$refs.media.getBoundingClientRect().height;
    },
    onEnter(ev) {
      if (this.isDevice) return;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.mediaW = this.$refs.media.getBoundingClientRect().width;
          this.mediaH = this.$refs.media.getBoundingClientRect().height;
        });
      });
      if (this.timer) clearTimeout(this.timer);
      this.setColors(this.project);
      this.setProjectImg(this.project.slug);
      this.isHover = true;
      this.isMoving = true;

      if (this.project.cover_type != "image") {
        this.$el.querySelector("video").currentTime = 0;
      }
      transform(this.$refs.media, {
        translate3d: [
          MouseHelper.x + this.mediaW * (MouseHelper.x / this.w - 0.5),
          MouseHelper.y + this.scrollTop - this.mediaH / 2,
          0
        ]
      });
    },
    onLeave(ev) {
      if (this.isDevice) return;
      this.resetProjectImg();
      this.isHover = false;
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isMoving = false;
      }, 500);
      this.setProjectImg(null);
    },
    tick(scrollTop) {
      if (this.isDevice) return;
      this.scrollTop = scrollTop;
      if (this.isMoving) {
        transform(this.$refs.media, {
          translate3d: [
            MouseHelper.easeX -
              this.mediaW * (MouseHelper.easeX / this.w) -
              this.left,
            MouseHelper.easeY + scrollTop - this.mediaH / 2,
            0
          ]
        });
      }
    }
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

.projectLink {
  @extend .fzLarge;
  position: relative;

  a {
    color: inherit;

    .client, .project {
      vertical-align: baseline;
      text-transform: uppercase;
      display: inline-block;
    }

    .client {
      margin-right: 2.1vw;
    }

    .project {
      // font-weight 300
      position: relative;
      text-transform: initial;
    }
  }

  strong {
    display: block;
    line-height: 1;
    @extend .fzSml;
  }

  br {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .page.project & {
      text-align: center;
    }
  }
}
</style>


<style lang="stylus">
.projectLink {
  a {
    .bar {
      transition: background 0.5s;
    }

    @media screen and (max-width: 1600px) {
      br {
        display: block !important;
      }

      .bar {
        display: none;
      }
    }

    .media {
      width: 45vw;
      max-width: 780px;
      position: fixed;
      opacity: 0;
      top: 0;
      left: 0;
      display: block;
      pointer-events: none;
      transition: opacity 0.3s;
      z-index: -1;

      &.isHover {
        opacity: 1;
        transition: opacity 0.2s;
      }

      img, video {
        width: 100%;
      }
    }
  }
}
</style>
