<template>
  <article class="clients">
    <v-title title="Clients" class="js-opacity" />
    <ul>
      <v-client v-for="(client,i) in clients" :key="`client-${i}`" :client="client" />
    </ul>
  </article>
</template>

<script>
import vTitle from "@/components/Title.vue";
import vClient from "@/components/home/Client.vue";
import { mapState } from "vuex";
export default {
  components: {
    vClient,
    vTitle
  },
  computed: {
    ...mapState(["clients"])
  }
};
</script>

<style lang="stylus" scoped>
.clients {
  ul {
    width: 106%;
    margin: 0 -3%;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 920px) {
    ul {
    }
  }

  @media screen and (max-width: 639px) {
    ul {
      width: 100%;
      margin: 0px;
    }
  }
}
</style>
