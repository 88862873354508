<template>
  <div
    class="scroller"
    :class="{'transition': isTransitioning}"
    :style="{'background-color': color}"
  ></div>
</template>

<script>
import transform from "dom-transform";
import anime from "animejs";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      isTransitioning: false
    };
  },
  props: ["color"],
  // computed:{
  //   ...mapState(['home']),
  //   ...mapGetters(['project'])
  // },
  methods: {
    tick(scrollTop) {
      if (!this.isTransitioning)
        transform(this.$el, { scaleX: scrollTop / this.pageHeight });
    },
    doTransition() {
      this.isTransitioning = true;
      anime({
        targets: this.$el,
        scaleX: 1,
        translateX: "100%",
        easing: "easeOutQuad",
        duration: 900,
        complete: () => {
          anime({
            targets: this.$el,
            scaleX: 0,
            opacity: 0,
            translateX: "0",
            duration: 100,
            complete: () => {
              this.$el.style.opacity = 1;
              this.isTransitioning = false;
            }
          });
        }
      });
    },
    resize(w, h, pageHeight) {
      if (w && h) {
        this.w = w;
        this.h = h;
        this.pageHeight = pageHeight - this.h;
      }
    }
  },
  watch: {}
};
</script>

<style lang="stylus" scoped>
@import './../styles/variables.styl';

.scroller {
  z-index: 10;
  height: 4px;
  width: 100%;
  position: fixed;
  top: 0;
  will-change: transform;
  transform-origin: 0 0;
  transition: transform 0.3s ease-out-quad;

  &.transition {
    transition: none;
  }
}
</style>
