
import Emitter from '@/assets/js/utils/EventsEmitter'
class ScrollHelper {

    constructor()  {
      this.ease = 0
      this.scrollTop = 0
      this.tempScroll = 0
      this.diffScroll = 0
      this._preventDefault = this.preventDefault.bind(this)
      this._onScroll = this.onScroll.bind(this)
      this.easeSlow = 0

      if(process.browser){
        this.doc = document.documentElement
        window.addEventListener('scroll', this._onScroll)
      }
      Emitter.on('WINDOW:DOSCROLL', this.scrollTo.bind(this))
    }
  
    onScroll() {
      let scroll = (window.pageYOffset || this.doc.scrollTop)  - (this.doc.clientTop || 0)
      if(scroll != this.scrollTop){
        this.scrollTop = (window.pageYOffset || this.doc.scrollTop)  - (this.doc.clientTop || 0)
        this.scrollTop = Math.max(this.scrollTop,0)
      }
    }
    scrollTo() {
      window.scrollTo(0,window.innerHeight)
      
    }

    goTo(val) {
      this.ease = val
      this.scrollTop = val
      this.tempScroll = val
      this.diffScroll = val
      this.easeSlow = val
      window.scrollTo(0,val)
    }

  
    tick() {
      this.ease = Math.round(this.lerp(this.ease, this.scrollTop, 0.07)*100)/100
      this.easeSlow = Math.round(this.lerp(this.easeSlow, this.scrollTop, 0.05)*100)/100
      this.diffScroll = Math.round(this.lerp(this.tempScroll, this.ease, 0.05)*100)/100 - this.tempScroll
      this.tempScroll = this.ease
    }
    lerp(x, y, r) {
      return x + ((y - x) * r);
    }
  
    preventDefault(e) {
      e = e || window.event;
      if (e.preventDefault)
          e.preventDefault();
      e.returnValue = false;
    }
  
    disableScroll() {
      if(window.addEventListener) window.addEventListener('DOMMouseScroll', this._preventDefault, false)
      window.onwheel = this._preventDefault; // modern standard
      window.onmousewheel = document.onmousewheel = this._preventDefault; // older browsers, IE
  
    }
  
    enableScroll() {
        if(window.removeEventListener) window.removeEventListener('DOMMouseScroll', this._preventDefault, false)
        window.onmousewheel = document.onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
    }
  }
  
  
  export default new ScrollHelper();
  