<template>
  <div :class="{'show': shown}">
    <video
      playsinline
      loop
      muted
      autoplay
      :src="media.url"
      class="imgMed container"
      :style="{'width': thisWidth}"
      preload="auto"
    />
  </div>
</template>

<script>
import ItemMixin from "@/components/project/ItemMixin.vue";
export default {
  mixins: [ItemMixin],
  props: ["media", "width"],
  computed: {
    url() {
      //return this.src
      let url = this.src;
      url = url.replace(
        "https://manifestomanifesto.com/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      url = url.replace(
        "http://manifestomanifesto.com/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      url = url.replace(
        "https://manifesto.local/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      url = url.replace(
        "http://manifesto.local/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      return url;
    },
    thisWidth() {
      return this.width ? this.width + "%" : "84%";
    }
  },
  methods: {
    resize(w, h) {
      const ratio = 1980 / 1020;
      if (w && h) {
        this.w = w;
        this.h = h;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

div {
  // padding 10vh 0
}

.imgMed {
  position: relative;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 130px) !important;
  }

  @media screen and (max-width: 639px) {
    width: calc(100% - 80px) !important;
  }
}
</style>
