<template>
  <header
    ref="logo"
    :class="{ 'isLarge':isLarge, 'container-text':true, 'hideEls':projectImg !== null }"
    v-if="$route.name == 'home'"
  >
    <router-link :to="{ name: 'home' }">
      <strong class="title mbSml js-opacity">
        <span>Dominique Picone</span>
      </strong>
    </router-link>
  </header>
</template>

<script>
import vTitle from "@/components/Title.vue";
import anime from "animejs";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    vTitle
  },
  data() {
    return {
      isLarge: true
    };
  },
  computed: {
    ...mapState(["home", "fontColor"]),
    ...mapGetters(["projectImg"])
  },
  methods: {
    resize(w, h) {
      if (w <= 639) {
        this.isLarge = false;
      } else {
        this.isLarge = true;
      }
    },
    show() {
      anime({
        targets: this.$refs.logo,
        opacity: [0, 1],
        duration: 500,
        easing: "easeInOutQuad"
      });
    },
    hide() {
      anime({
        targets: this.$refs.logo,
        opacity: 0,
        duration: 1000,
        easing: "easeInOutQuad"
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../styles/variables.styl';

/*
header {
  height: 100px;
  line-height: 100px;
  position: fixed;

  a {
    width: 100%;
    height: 100%;
    display: block;
    color: inherit;

    .title {
      line-height: 1;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  left: 160px;

  @media screen and (max-width: 2100px) {
    left: 100px;
  }

  @media screen and (max-width: 1024px) {
    left: 80px;
  }

  @media screen and (max-width: 639px) {
    left: 30px;
  }
}
*/
header {
  position: fixed;
  left: 50%;
  top: 80px;
  transform: translateX(-820px);
  z-index: 8;

  @media screen and (max-width: 1920px) {
    left: 80px;
    transform: translateX(0);
  }

  @media screen and (max-width: 640px) {
    top: 20px;
    left: 8px;
    padding: 20px;
  }

  a {
    color: inherit;
    @extend .fzSml;
  }
}
</style>
