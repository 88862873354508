<template>
  <figure class="imgLarge" :class="{'show': shown}">
    <video playsinline loop muted autoplay :src="media.url" ref="video" preload="auto" />
  </figure>
</template>

<script>
import ItemMixin from "@/components/project/ItemMixin.vue";
export default {
  mixins: [ItemMixin],
  props: ["media"],
  computed: {
    url() {
      //return this.src
      let url = this.src;
      url = url.replace(
        "https://manifestomanifesto.com/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      url = url.replace(
        "http://manifestomanifesto.com/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      url = url.replace(
        "https://manifesto.local/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      url = url.replace(
        "http://manifesto.local/wp/wp-content/uploads/",
        "https://res.cloudinary.com/drj7uxzqt/video/upload/manifesto/"
      );
      return url;
    }
  },
  methods: {
    resize(w, h) {
      const ratio = 1980 / 1020;
      if (w && h) {
        this.w = w;
        this.h = h;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

.imgLarge {
  position: relative;
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;
}
</style>
